import request from '@/utils/axios'
// 注：上传文件时，把 params 改成 data

// 登录
export function Login (params) {
  return request({
    url: '/api/login',
    method: 'post',
    params
  })
}
