<template>
    <div class="login" >
        <div class="w1000" >
            <div class="right">
                <div class="title">
                    欢迎访问全优视频在线学习系统
                </div>
                <div class="codebox">
                    <div class="height30"></div>
                    <el-form ref="form" :model="form" label-width="80px">
                        <el-form-item label="学习账号">
                            <el-input v-model="form.name" clearable @keyup.enter.native="onSubmit()"></el-input>
                        </el-form-item>
                        <el-form-item label="学习密码">
                            <el-input v-model="form.password" show-password @keyup.enter.native="onSubmit()"></el-input>
                        </el-form-item>
                        <div class="height20"></div>
                        <el-form-item>
                            <el-button type="success" @click="onSubmit" round>登录</el-button>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import { Login } from "@/api/login.js";
export default {
    data() {
        return {
            form: {
                name: "",
                password: ""
            }
        };
    },
    created() {
    },
    mounted() {

    },
    methods: {
        onSubmit() {
            let { name, password } = this.form;
            if (!name || !password) {
                this.$message({
                    showClose: true,
                    message: "账号密码不能为空",
                    type: "error",
                });
                return
            }
            Login({ phone: name, password: password }).then((res) => {
                if (res.code == 200) {
                    this.$cookie.set("type", res.data.user.type); //存储当前是什么身份
                    this.$cookie.set("token", res.data.token);  //存储用户当前标识
                    this.$cookie.set("username", res.data.user.username);  //存储用户当前标识
                    this.$router.push("/index");
                    this.$message({
                        showClose: true,
                        message: "登录成功！",
                        type: "success",
                    });
                }
            });
        },
    },
};
</script>
  
<style scoped lang="scss">
.el-button.is-round {
    width: 200px;
    background: #079562;
}

.height30 {
    width: 100%;
    height: 30px;
}

.height20 {
    width: 100%;
    height: 20px;
}

.login {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    background: url("../assets/img/login_bck.png") no-repeat center;
    background-size: 100% 100%;
}

.w1000 {
    width: 370px;
    height: 550px;
    margin: auto;
    display: flex;
    align-items: center;
}

.left {
    position: relative;

    .t1 {
        width: 172px;
        height: 79px;
        border: 3px solid white;
        border-bottom: none;
        margin-left: 66px;
    }

    .t2 {
        font-size: 87px;
        font-weight: 800;
        color: #ffffff;

        >b {
            color: #ff9b02;
        }
    }

    .t3 {
        font-size: 49px;
        font-weight: 500;
        color: #ffffff;
        margin-top: 30px;

        >b {
            color: #ff9b02;
        }
    }

    .t4 {
        display: flex;
        align-items: center;
        font-size: 40px;
        font-weight: 400;
        color: #ffffff;
        margin-top: 10px;
        margin-bottom: -15px;
        position: relative;
        z-index: 2;

        >img {
            height: 153px;
        }
    }

    .t5 {
        width: 172px;
        height: 79px;
        border: 3px solid white;
        border-top: none;
        margin-left: 66px;
    }
}

.right {
    width: 369px;
    height: 424px;
    background: rgba(255, 255, 255, 0.84);
    box-shadow: 0px 2px 43px 0px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    overflow: hidden;

    .title {
        height: 74px;
        background: #079562;
        font-size: 18px;
        font-weight: bold;
        color: #ffffff;
        text-align: center;
        line-height: 74px;
    }

    .codebox {
        width: 90%;
        margin: 30px auto;
    }
}
</style>
  